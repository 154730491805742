import { Link } from "gatsby"
import React from "react"

const Footer = ({ siteTitle, menuLinks }) => (
  <footer>
    <div
      style={{
        textAlign: `center`,
        margin: `0 auto`,
        padding: `5rem 0`,
      }}
    >
      <span>
        © {new Date().getFullYear()} |{" "}
        <Link className="white" to="/datenschutz">
          Datenschutz
        </Link>{" "}
        |{" "}
        <Link className="white" to="/impressum">
          Impressum
        </Link>
      </span>
    </div>
  </footer>
)

export default Footer
