import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "../images/SW-Logo.svg"

function Logo() {
  // Import result is the URL of your image
  return <img className="logo" src={logo} alt="Strategie und Wert Logo" />
}

function Header({ siteTitle, menuLinks }) {
  const [show, toggleMenu] = useState("hide")

  return (
    <header>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1300,
          padding: `1.45rem 1.0875rem`,
          display: "flex",
        }}
      >
        <h1
          className="logo__link"
          style={{ margin: `2rem`, textTransform: `uppercase` }}
        >
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            <Logo />
          </Link>
        </h1>
        <div>
          <nav>
            <ul
              className="nav"
              style={{ display: "flex", flex: 1, margin: "1.5rem 0 0 5rem" }}
            >
              <li className="nav">
                <Link className="nav-link" to="/#philosophie">
                  Unsere <br />
                  <b>Philosophie</b>
                </Link>
              </li>

              <li className="nav">
                <Link className="nav-link" to="/#expertise">
                  Unsere <br />
                  <b>Expertise</b>
                </Link>
              </li>
              <li className="nav">
                <Link className="nav-link" to="/#leistungen">
                  Unsere <br />
                  <b>Leistungen</b>
                </Link>
              </li>

              <li className="nav">
                <Link className="nav-link" to="/about">
                  Über
                  <br /> <b>Uns</b>
                </Link>
              </li>
              <li className="nav">
                <Link className="nav-link" to="/#kontakt">
                  Kontakt
                </Link>
              </li>
            </ul>
            <ul className="mobile">
              {show === "hide" ? (
                <button
                  className="menu-button"
                  onClick={() => toggleMenu("show")}
                >
                  <i className="material-icons ">menu</i>
                </button>
              ) : (
                <button
                  className="menu-button"
                  onClick={() => toggleMenu("hide")}
                >
                  <i className="material-icons">close</i>
                </button>
              )}
            </ul>
            {show === "show" ? (
              <div className="overlay">
                <div className="overlay-content">
                  {menuLinks.map(link => (
                    <li
                      key={link.name}
                      style={{
                        textAlign: `center`,
                        listStyleType: `none`,
                        padding: `1rem`,
                      }}
                    >
                      <Link
                        onClick={() => toggleMenu("hide")}
                        className="mobile-link"
                        to={link.link}
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            ) : null}
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
