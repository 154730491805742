import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

export default function Cookie() {
  return (
    <CookieConsent
      location="bottom"
      disableStyles={true}
      buttonText="Einverstanden"
      style={{
        background: "#FFF",
        color: "#004593",
        right: "1px",
        float: "right",
      }}
      buttonStyle={{
        background: "#004593",
        color: "#FFF",
        fontSize: "14px",
        padding: "1rem 1rem 1rem 1rem",
      }}
      containerClasses="cookie"
    >
      <b>
        <span role="img" aria-label="cookie">
          🍪
        </span>{" "}
        Verwendung von Cookies
      </b>
      <p>
        Um unsere Webseite für Sie optimal zu gestalten und fortlaufend
        verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung
        der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere
        Informationen zu Cookies erhalten Sie in unserer{" "}
        <Link to="/datenschutz">Datenschutzerklärung</Link>
      </p>
    </CookieConsent>
  )
}
